import { React, useEffect } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { marketCodeManager } from '@/recoil/marketCode'
import { isMobileState } from '@/recoil/layout'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'

import moment from 'moment'
import Layout from '@/layouts/Default'

// import Hero from '@/components/Hero'
// import NewsFeed from '@/components/NewsFeed'
// import CircularLabelButton from '@/components/CircularLabelButton'
// import Community from '@/components/Community'
// import Events from '@/components/Events'
// import Podcast from '@/components/Podcast'
// import LearnManners from '@/components/LearnManners'
import JournalCardBlock from '@/components/JournalCardBlock'
// import JournalMedias from '@/components/JournalMedias'
// import NewsSlider from '@/components/NewsSlider'
import HeroJournal from '@/components/HeroJournal'

const JournalPage = ({
  data,
  pageContext: {
    pagePath,
    marketCode: pageMarketCode
  }
}) => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0)
    }
  }, [])
  const isMobile = useRecoilValue(isMobileState)
  const [marketCode, setMarketCode] = useRecoilState(marketCodeManager)

  const newsData = data.drupal.nodeById.fieldJournalNewsList
  const sliderNewsData = data.drupal.nodeById.fieldNewsSlider
  const featuredData = data.drupal.nodeById.fieldNewsFeatured
  const videosData =
    data.drupal.nodeById.fieldJournalVideoBlock?.entity.fieldVideoCarrousel
      .entity
  const socialData = data.drupal.nodeById.fieldJournalJoinOurCommunity?.entity

  const defaultFilter = {
    label: 'All',
    catId: 'all'
  }
  const newsFilters = [defaultFilter]
  const videosFilters = [defaultFilter]
  const eventsFilters = ['all']

  const regionNames = new Intl.DisplayNames([marketCode.split('-')[0]], {
    type: 'region'
  })

  const events = data.drupal.eventsQuery.entities
    .map((event, index) => {
      if (
        event?.fieldEventCountryLocation &&
        eventsFilters.indexOf(
          regionNames.of(event?.fieldEventCountryLocation)
        ) === -1
      ) {
        eventsFilters.push(regionNames.of(event?.fieldEventCountryLocation))
      }

      const date = event?.fieldEventEndDate?.value ? event?.fieldEventEndDate?.value : event?.fieldEventStartDate?.value

      return {
        name: event?.title,
        url: event?.path?.alias,
        startDate: event?.fieldEventStartDate?.value,
        endDate: event?.fieldEventEndDate?.value,
        onGoing: moment.utc(new Date()).isBefore(date),
        ref: event?.fieldEventLocation,
        hours: event?.fieldEventHours,
        country: event?.fieldEventCountryLocation,
        city: event?.fieldEventLocation,
        content: event?.fieldEventDescription,
        images: [event?.fieldEventCover?.url],
        timestamp: moment(event?.fieldEventStartDate?.value).unix()
      }
    })
    .filter((event) => event.onGoing)
    .sort((ea, eb) => ea.timestamp - eb.timestamp)

  const podcasts = data.drupal.nodeById.fieldJournalPodcastItem.map(
    (podcast) => ({
      name: podcast.entity?.fieldStandardPodcastName,
      url: podcast.entity?.fieldStandardPodcastLink?.uri,
      category: podcast.entity?.fieldStandardPodcastCategory
        ?.map((cat) => cat.entity?.name)
        .join(', '),
      text: podcast.entity?.fieldStandardPodcastDesc,
      color: podcast.entity?.fieldPodcastSquareColor
    })
  )

  const getNewsSliderList = (_) => {
    const news = []

    for (let i = 0; i < sliderNewsData.length; i++) {
      const item = sliderNewsData[i]?.entity
      if (item) {
        news.push({
          video: item.fieldNewsVideo?.url.path ?? null,
          catId: item.fieldNewsCategories.map(c => c.entity?.entityId),
          category: item.fieldNewsCategories[0].entity?.name,
          title: item.fieldNewsTitle,
          text: item.fieldNewsShortDescription,
          image: (isMobile && item.fieldNewsCoverMobile?.url)
            ? item.fieldNewsCoverMobile?.url
            : item.fieldNewsCover?.url,
          imageAlt: (isMobile && item.fieldNewsCoverMobile?.alt)
            ? item.fieldNewsCoverMobile?.alt
            : item.fieldNewsCover?.alt,
          url: item.path.alias,
          ctaTo: item.fieldNewsVideo?.url.path ? '' : item.path.alias,
          date: item.fieldNewsDate.date
        })
        
        item.fieldNewsCategories.forEach((f) => {
          if (
            !newsFilters.find(
              (filter) =>
                filter.catId === f.entity?.entityId
            )
          ) {
            newsFilters.push({
              label: f.entity?.name,
              catId: f.entity?.entityId,
              weight: f.entity?.weight ?? 0
            })
          }
        })
      }
    }

    return news.sort((a, b) => b.date - a.date)
  }
  const getNewsList = (_) => {
    const news = []

    for (let i = 0; i < newsData.length; i++) {
      const item = newsData[i]?.entity

      if (item) {
        news.push({
          video: item.fieldNewsVideo?.url.path ?? null,
          catId: item.fieldNewsCategories.map(c => c.entity?.entityId),
          category: item.fieldNewsCategories[0].entity?.name,
          title: item.fieldNewsTitle,
          text: item.fieldNewsShortDescription,
          image: (isMobile && item.fieldNewsCoverMobile?.url)
            ? item.fieldNewsCoverMobile?.url
            : item.fieldNewsCover?.url,
          imageAlt: (isMobile && item.fieldNewsCoverMobile?.alt)
            ? item.fieldNewsCoverMobile?.alt
            : item.fieldNewsCover?.alt,
          url: item.path.alias,
          date: item.fieldNewsDate.date
        })
        item.fieldNewsCategories.forEach((f) => {
          if (
            !newsFilters.find(
              (filter) =>
                filter.catId === f.entity?.entityId
            )
          ) {
            newsFilters.push({
              label: f.entity?.name,
              catId: f.entity?.entityId,
              weight: f.entity?.weight ?? 0
            })
          }
        })
      }
    }

    return news.sort((a, b) => b.date - a.date)
  }

  const getFeaturedList = (_) => {
    const news = []

    for (let i = 0; i < featuredData.length; i++) {
      const item = featuredData[i]?.entity

      if (item) {
        news.push({
          video: item.fieldNewsVideo?.url.path ?? null,
          catId: item.fieldNewsCategories.map(c => c.entity?.entityId),
          category: item.fieldNewsCategories[0].entity?.name,
          title: item.fieldNewsTitle,
          text: item.fieldNewsShortDescription,
          image: isMobile
            ? item.fieldNewsCoverMobile?.url
            : item.fieldNewsCover?.url,
          imageAlt: isMobile
            ? item.fieldNewsCoverMobile?.alt
            : item.fieldNewsCover?.alt,
          url: item.path.alias,
          date: item.fieldNewsDate.date
        })

        if (
          !newsFilters.find(
            (filter) =>
              filter.catId === item.fieldNewsCategories[0].entity?.entityId
          )
        ) {
          newsFilters.push({
            label: item.fieldNewsCategories[0].entity?.name,
            catId: item.fieldNewsCategories[0].entity?.entityId,
            weight: item.fieldNewsCategories[0].entity?.weight ?? 0
          })
        }
      }
    }

    return news.sort((a, b) => b.date - a.date)
  }
  const featuredItems = getFeaturedList()
  const featuredItemsId = featuredItems.map(el => el.url)
  const newSliderItems = getNewsSliderList().filter(el => !featuredItemsId.includes(el.url))
  const newItems = getNewsList().filter(el => !featuredItemsId.includes(el.url))

  const getVideosList = (_) => {
    const videos = []

    for (let i = 0; i < videosData.fieldVideoCarrouselItems.length; i++) {
      const item = videosData.fieldVideoCarrouselItems[i].entity

      videos.push({
        title: item.fieldTitleVideoItem,
        catId: item.fieldCategoryVideoItem[0].entity?.entityId,
        category: item.fieldCategoryVideoItem[0].entity?.name,
        text: item.fieldDescriptionVideoItem,
        thumb: item.fieldCoverImageVideoItem?.url,
        thumbAlt: item.fieldCoverImageVideoItem?.alt,
        source: item.fieldVideoLinkVideoItem?.url.path
      })

      if (
        item.fieldCategoryVideoItem[0].entity?.entityId &&
        !videosFilters.find(
          (e) => e.catId === item.fieldCategoryVideoItem[0].entity.entityId
        )
      ) {
        videosFilters.push({
          label: item.fieldCategoryVideoItem[0].entity?.name,
          catId: item.fieldCategoryVideoItem[0].entity?.entityId
        })
      }
    }

    return videos
  }

  const getSocialList = (_) => {
    const socials = []
    for (
      let i = 0;
      i < socialData.fieldSocialPostsList.entity.fieldSocialPostItem.length;
      i++
    ) {
      const item =
        socialData.fieldSocialPostsList.entity.fieldSocialPostItem[i].entity

      socials.push({
        url: item.fieldSocialPostItemLink.uri,
        coverImg: item.fieldSocialPostItemCoverImg.url,
        coverAlt: item.fieldSocialPostItemCoverImg.alt,
        logo: item.fieldSocialPostLogo.url,
        logoAlt: item.fieldSocialPostLogo.alt,
        postUsername: item.fieldSocialPostItemUsername,
        postSocialAccount: item.fieldSocialPostUserAccount.title,
        postSocialAccountUrl: item.fieldSocialPostUserAccount.url.path
      })
    }
    return socials
  }

  const socialsList = getSocialList()

  return (
    <Layout
      entityBundle='journal'
      path={ pagePath }
      pageType='Editorial'
      pageMarketCode={ pageMarketCode }
    >
      <Helmet>
        <title>
          {data.drupal.nodeById.fieldJournalMetaTags.entity.fieldMetaTitle}
        </title>
        <meta
          name='description'
          content={ data.drupal.nodeById.fieldJournalMetaTags.entity.fieldMetaDescription }
        />
        <meta
          property='og:title'
          content={ data.drupal.nodeById.fieldJournalMetaTags.entity.fieldMetaTitle }
        />
        <meta
          property='og:description'
          content={ data.drupal.nodeById.fieldJournalMetaTags.entity.fieldMetaDescription }
        />
        <meta property='og:url' content={ `${process.env.URL || process.env.GATSBY_DRUPAL_URL}${pagePath}` } />
        <meta property='og:site_name' content='Cloudy Bay' />
        <meta property='og:type' content='article' />
        {
          data.drupal.nodeById.fieldJournalMetaTags.entity.fieldMetaOpenGraphImage &&
          (
            <meta
              property='og:image'
              content={ data.drupal.nodeById.fieldJournalMetaTags.entity.fieldMetaOpenGraphImage?.url }
            />
          )
        }
      </Helmet>
      <HeroJournal slides={ newSliderItems } />

      {/* <NewsFeed */}
      {/*  subtitle={ data.drupal.nodeById?.fieldNewsBlockSubtitle } */}
      {/*  title={ data.drupal.nodeById?.fieldNewsBlockTitle } */}
      {/*  text={ data.drupal.nodeById?.fieldNewsBlockDescription } */}
      {/*  item={ { */}
      {/*    subtitle: topNewsData.fieldNewsCategories.title, */}
      {/*    title: topNewsData.fieldNewsTitle, */}
      {/*    text: topNewsData.fieldNewsShortDescription, */}
      {/*    image: isMobile */}
      {/*      ? topNewsData.fieldNewsCoverMobile.url */}
      {/*      : topNewsData.fieldNewsCover.url, */}
      {/*    alt: isMobile */}
      {/*      ? topNewsData.fieldNewsCoverMobile.alt */}
      {/*      : topNewsData.fieldNewsCover.alt, */}
      {/*    url: topNewsData.path.alias */}
      {/*  } } */}
      {/* /> */}
      {/* <JournalMedias filters={ newsFilters } items={ newItems } /> */}
      <JournalCardBlock
        title={ data.drupal.nodeById.title }
        filters={ newsFilters }
        items={ newItems }
        featureItems={ featuredItems }
      />

      {/* <div id='watch'> */}
      {/*  <LearnManners */}
      {/*    title={ videosData.fieldVideoCarrouselTitle } */}
      {/*    items={ getVideosList() } */}
      {/*    filters={ { */}
      {/*      placeholder: 'Sort By', */}
      {/*      items: videosFilters */}
      {/*    } } */}
      {/*  /> */}
      {/* </div> */}

      {/* {podcasts.length > 0 && ( */}
      {/*  <Podcast */}
      {/*    title={ data.drupal.nodeById?.fieldPodcastBlockTitle } */}
      {/*    items={ podcasts } */}
      {/*  /> */}
      {/* )} */}

      {/* {events.length > 0 && ( */}
      {/*  <Events */}
      {/*    title={ data.drupal.nodeById?.fieldEventBlockTitle } */}
      {/*    subtitle={ data.drupal.nodeById?.fieldEventBlockSubtitle } */}
      {/*    items={ events } */}
      {/*    filters={ { */}
      {/*      placeholder: 'Sort By', */}
      {/*      items: eventsFilters */}
      {/*    } } */}
      {/*  /> */}
      {/* )} */}

      {/* <Community */}
      {/*   title={ socialData.fieldSocialPostsList.entity.fieldSocialListTitle } */}
      {/*   subtitle={ */}
      {/*    socialData.fieldSocialPostsList.entity.fieldSocialListSubtitle */}
      {/*  } */}
      {/*   text={ socialData.fieldSocialPostsList.entity.fieldSocialListDescription } */}
      {/*   action={ */}
      {/*     <CircularLabelButton */}
      {/*       id='textcercle2' */}
      {/*       label={ */}
      {/*        socialData.fieldSocialPostsList.entity.fieldSocialListCta?.title */}
      {/*      } */}
      {/*       to={ */}
      {/*        socialData.fieldSocialPostsList.entity.fieldSocialListCta?.url */}
      {/*          .path */}
      {/*      } */}
      {/*       target='_blank' */}
      {/*       nbLabels={ isMobile ? 3 : 5 } */}
      {/*     /> */}
      {/*  } */}
      {/*   items={ socialsList } */}
      {/* /> */}
    </Layout>
  )
}

export const query = graphql`
  query JournalDataQuery {
    drupal {
      nodeById(id: "272") {
        ... on Drupal_NodeJournal {
          nid
          title
          fieldEventBlockTitle
          fieldEventBlockSubtitle
          fieldNewsBlockTitle
          fieldNewsBlockSubtitle
          fieldNewsBlockDescription
          fieldJournalMetaTags {
            entity {
              ... on Drupal_ParagraphMetaTags {
                fieldMetaTitle
                fieldMetaDescription
                fieldMetaOpenGraphImage {
                  url
                }
              }
            }
          }
          fieldJournalJoinOurCommunity {
            entity {
              ... on Drupal_NodeStandardSocialPosts {
                fieldSocialPostsList {
                  entity {
                    ... on Drupal_ParagraphSocialPostItemList {
                      fieldSocialListCta {
                        uri
                        title
                        options
                        url {
                          path
                        }
                      }
                      fieldSocialPostItem {
                        entity {
                          ... on Drupal_ParagraphSocialPostItem {
                            fieldSocialPostLogo {
                              height
                              url
                              width
                              targetId
                              alt
                              title
                            }
                            fieldSocialPostItemLink {
                              uri
                              title
                              options
                            }
                            fieldSocialPostItemCoverImg {
                              url
                              alt
                            }
                            fieldSocialPostItemUsername
                            fieldSocialPostUserAccount {
                              title
                              url {
                                path
                              }
                            }
                          }
                        }
                      }
                      fieldSocialListTitle
                      fieldSocialListSubtitle
                      fieldSocialListDescription
                    }
                  }
                }
                fieldSocialPostsMarkets {
                  targetId
                }
              }
            }
          }
          fieldJournalHeroBlock {
            entity {
              ... on Drupal_ParagraphHeroBlock {
                id
                fieldHeroTitle
                fieldHeroSubtitle
                fieldHeroDescription
                fieldBackgroundDesktop {
                  url
                  alt
                }
                fieldBackgroundMobile {
                  url
                  alt
                }
                fieldHeroCta {
                  url {
                    path
                  }
                  title
                }
              }
            }
          }
          fieldNewsBlockTitle
          fieldNewsBlockSubtitle
          fieldNewsBlockDescription
          fieldNewsSlider {
            entity {
              ... on Drupal_NodeNews {
                path {
                  alias
                }
                fieldNewsTitle
                fieldNewsVideo {
                  url {
                    path
                  }
                }
                fieldNewsDate {
                  date
                }
                fieldNewsShortDescription
                fieldNewsCategories {
                  entity {
                    ... on Drupal_TaxonomyTermNewsCategories {
                      weight
                      name
                      entityId
                    }
                  }
                }
                fieldNewsCover {
                  alt
                  url
                }
                fieldNewsCoverMobile {
                  alt
                  url
                }
                fieldNewsIntroduction
              }
            }
          }
          fieldNewsTopNews {
            entity {
              ... on Drupal_NodeNews {
                title
                path {
                  alias
                }
                fieldNewsTitle
                fieldNewsShortDescription
                fieldNewsCoverMobile {
                  alt
                  url
                }
                fieldNewsCover {
                  alt
                  url
                }
                fieldNewsCategories {
                  entity {
                    weight
                    name
                  }
                }
              }
            }
          }
          fieldJournalNewsList {
            entity {
              ... on Drupal_NodeNews {
                path {
                  alias
                }
                fieldNewsTitle
                fieldNewsVideo {
                  url {
                    path
                  }
                }
                fieldNewsDate {
                  date
                }
                fieldNewsShortDescription
                fieldNewsCategories {
                  entity {
                    ... on Drupal_TaxonomyTermNewsCategories {
                      name
                      entityId
                      weight
                    }
                  }
                }
                fieldNewsCover {
                  alt
                  url
                }
                fieldNewsCoverMobile {
                  alt
                  url
                }
                fieldNewsIntroduction
              }
            }
          }
          fieldNewsFeatured {
            entity {
              ... on Drupal_NodeNews {
                path {
                  alias
                }
                fieldNewsTitle
                fieldNewsVideo {
                  url {
                    path
                  }
                }
                fieldNewsDate {
                  date
                }
                fieldNewsShortDescription
                fieldNewsCategories {
                  entity {
                    ... on Drupal_TaxonomyTermNewsCategories {
                      name
                      entityId
                      weight
                    }
                  }
                }
                fieldNewsCover {
                  alt
                  url
                }
                fieldNewsCoverMobile {
                  alt
                  url
                }
                fieldNewsIntroduction
              }
            }
          }
          fieldEventBlockTitle
          fieldEventBlockSubtitle
          fieldPodcastBlockTitle
          fieldJournalPodcastItem {
            entity {
              ... on Drupal_NodePodcasts {
                fieldPodcastSquareColor
                fieldStandardPodcastLink {
                  uri
                }
                fieldStandardPodcastDesc
                fieldStandardPodcastName
                fieldStandardPodcastCategory {
                  entity {
                    ... on Drupal_TaxonomyTermPodcastCategories {
                      name
                    }
                  }
                }
              }
            }
          }
          fieldJournalVideoBlock {
            entity {
              title
              ... on Drupal_NodeVideoCarrousel {
                fieldVideoCarrouselScope
                fieldVideoCarrousel {
                  entity {
                    ... on Drupal_ParagraphStandardVideoCarrousel {
                      id
                      fieldVideoCarrouselTitle
                      fieldVideoCarrouselItems {
                        entity {
                          ... on Drupal_ParagraphStandardVideoCarrouselItem {
                            id
                            fieldVideoLinkVideoItem {
                              url {
                                path
                              }
                            }
                            fieldTitleVideoItem
                            fieldDescriptionVideoItem
                            fieldCoverImageVideoItem {
                              url
                              alt
                              title
                            }
                            fieldCategoryVideoItem {
                              entity {
                                name
                                entityId
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      eventsQuery: nodeQuery(
        filter: {
          conditions: [
            { field: "type", value: "events", operator: EQUAL }
            # {field: "field_event_country", value: [$marketId], operator: EQUAL},
            { field: "field_event_types", value: "journal", operator: EQUAL }
            { operator: EQUAL, field: "status", value: "1" }
          ]
        }
        limit: 1000
      ) {
        entities {
          ... on Drupal_NodeEvents {
            title
            path {
              alias
            }
            fieldEventStartDate {
              value
              date
            }
            fieldEventEndDate {
              value
              date
            }
            fieldEventCountry {
              targetId
            }
            fieldEventCountryLocation
            fieldEventLocation
            fieldEventCover {
              height
              url
              width
              targetId
              alt
              title
            }
            fieldEventCoverMobile {
              height
              url
              width
              targetId
              alt
              title
            }
            fieldEventDescription
            fieldEventHours
          }
        }
      }
    }
  }
`

export default JournalPage
